import { query } from '@confluence/query-preloader-tools';

import { ModernizedBreadcrumbsQuery } from './ModernizedBreadcrumbsQuery.graphql';

export function preloadBreadcrumbsDropdown({
	spaceKey,
	contentId,
	isSpaceAliasFFEnabled = false,
}: {
	spaceKey: string;
	contentId?: string;
	isSpaceAliasFFEnabled: boolean;
}) {
	return query({
		query: ModernizedBreadcrumbsQuery,
		variables: {
			spaceKey,
			contentId,
			skipContent: !contentId,
			includeAlias: isSpaceAliasFFEnabled,
		},
	});
}
