import React, { useState, useContext, useRef, useEffect } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Flex, xcss, Pressable } from '@atlaskit/primitives';
import Popup from '@atlaskit/popup';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import { token } from '@atlaskit/tokens';

import { LoadableLazy, useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { useSpaceDetail } from '@confluence/space-utils';
import {
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
	BREADCRUMBS_EXPERIENCE,
	BREADCRUMBS_DROPDOWN_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';

import { preloadBreadcrumbsDropdown } from './preloadBreadcrumbsDropdown';
import { getIsTextTruncated } from './breadcrumbsHelpers';

const BreadcrumbsDropdownContent = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsDropdownContent" */ './BreadcrumbsDropdownContent'
			)
		).BreadcrumbsDropdownContent,
});

const BreadcrumbsPlaceholder = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BreadcrumbsPlaceholder" */ './BreadcrumbsPlaceholder'
			)
		).BreadcrumbsPlaceholder,
});

const separatorStyles = xcss({
	color: 'color.text.subtle',
	pointerEvents: 'none',
	flexShrink: '0',
	marginRight: 'space.050',
	marginLeft: 'space.050',
});

const Separator = () => <Box xcss={separatorStyles}>/</Box>;

const breadcrumbsStyles = xcss({
	minWidth: '24px', // needed for responsive breadcrumbs
	alignItems: 'center',
	flex: '0 1 auto',
});

const fadedSpaceNameTextStyles = xcss({
	maskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
	WebkitMaskImage: 'linear-gradient(to right, black 60%, transparent 100%)',
});

const spaceNameTextStyles = xcss({
	textOverflow: 'ellipsis',
	width: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	maxWidth: '100%',
	fontSize: 'font.body',
});

const noEllipsisStyles = xcss({
	textOverflow: 'unset',
});

const spaceNameBreadcrumbWithoutDropdownStyles = xcss({
	display: 'inline-block',
	cursor: 'default',
	flexShrink: 0,
	width: '100%',
	height: 'space.400',
	backgroundColor: 'color.background.neutral.subtle',
	borderRadius: '4px',
	marginRight: 'space.075',
	paddingTop: 'space.075',
	paddingBottom: 'space.075',
	paddingRight: 'space.100',
	paddingLeft: 'space.100',
	color: 'color.text.subtlest',
});

const spaceNameBreadcrumbDropdownButtonStyles = xcss({
	maxWidth: '120px',
	cursor: 'pointer',
	width: 'unset',
	marginRight: 'space.0',

	':hover': {
		textDecoration: 'underline',
		color: 'color.text.subtle',
	},
	':focus': {
		color: 'color.text.subtle',
	},
	':active': {
		color: 'color.text',
	},
});

const currentPageBreadcrumbStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	font: 'font.heading.xsmall',
	marginLeft: 'space.100',
	marginRight: 'space.075',
	minWidth: '24px',
});

type BreadcrumbsWithSpaceNameDropdownProps = {
	contentTitle: string;
	spaceKey: string;
	isSpaceAliasFFEnabled: boolean;
	contentId?: string;
	testId?: string;
	isContentTypesHeader?: boolean;
};

export const BreadcrumbsWithSpaceNameDropdown = ({
	contentTitle,
	spaceKey,
	contentId,
	isSpaceAliasFFEnabled,
	testId = 'breadcrumbs-with-space-name-dropdown',
	isContentTypesHeader = false,
}: BreadcrumbsWithSpaceNameDropdownProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isBreadcrumbsPopupOpen, setIsBreadcrumbsPopupOpen] = useState(false);
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { data: spaceData, error } = useSpaceDetail(spaceKey);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const breadcrumbsButtonTextRef = useRef<HTMLElement | null>(null);
	const [spaceNameIsTruncated, setSpaceNameIsTruncated] = useState(false);

	useEffect(() => {
		setSpaceNameIsTruncated(getIsTextTruncated(breadcrumbsButtonTextRef));
	}, [spaceKey]);

	// Note don't check for loading as when refreshing data we will get data={...} and loading=true
	// In this case if we display the placeholder it will flash before the data is displayed
	// We should continue render with existing data and then update when the new data is available
	if (error || !spaceData) {
		return (
			<>
				{error && (
					<ErrorDisplay error={error}>
						<ExperienceFailure name={BREADCRUMBS_EXPERIENCE} error={error} />
					</ErrorDisplay>
				)}
				<BreadcrumbsPlaceholder />
			</>
		);
	}
	const spaceName = spaceData.name || spaceData.alias || spaceKey;

	const shouldOpenDropdown = Boolean(contentTitle) || isContentTypesHeader;

	const onOpenBreadcrumbDropdown = () => {
		if (shouldOpenDropdown) {
			if (!isBreadcrumbsPopupOpen) {
				experienceTracker.start({
					name: BREADCRUMBS_DROPDOWN_EXPERIENCE,
				});

				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'opened',
						actionSubject: 'breadcrumbsDropdown',
						source: 'breadcrumbs',
					},
				}).fire();
			}

			setIsBreadcrumbsPopupOpen(!isBreadcrumbsPopupOpen);
		}
	};

	const popupContent = () => {
		return (
			<BreadcrumbsDropdownContent
				spaceKey={spaceKey}
				contentId={contentId}
				isSpaceAliasFFEnabled={isSpaceAliasFFEnabled}
				closeDropdownOnClickOfBreadcrumbItem={closeDropdownOnClickOfBreadcrumbItem}
			/>
		);
	};

	const closeDropdownOnClickOfBreadcrumbItem = () => {
		setIsBreadcrumbsPopupOpen(false);
	};

	const preloadBreadcrumbsDropdownOnSpaceNameHover = () => {
		void preloadBreadcrumbsDropdown({ spaceKey, contentId, isSpaceAliasFFEnabled });
	};

	return (
		<Flex xcss={breadcrumbsStyles} testId={testId}>
			<Popup
				isOpen={isBreadcrumbsPopupOpen}
				onClose={() => setIsBreadcrumbsPopupOpen(false)}
				placement="bottom-start"
				content={popupContent}
				shouldRenderToParent
				trigger={(triggerProps) => (
					<Pressable
						xcss={[
							spaceNameBreadcrumbWithoutDropdownStyles,
							shouldOpenDropdown && spaceNameBreadcrumbDropdownButtonStyles,
						]}
						onClick={onOpenBreadcrumbDropdown}
						onMouseEnter={preloadBreadcrumbsDropdownOnSpaceNameHover}
						aria-label="Breadcrumbs"
						{...triggerProps}
						{...ssrPlaceholderIdProp}
					>
						<Flex alignItems="center" gap="space.100">
							{shouldOpenDropdown && (
								<ChevronDownIcon label="" LEGACY_size="medium" color={token('color.icon.subtle')} />
							)}
							<Box
								ref={breadcrumbsButtonTextRef}
								xcss={[
									spaceNameTextStyles,
									shouldOpenDropdown && noEllipsisStyles,
									shouldOpenDropdown && spaceNameIsTruncated && fadedSpaceNameTextStyles,
								]}
							>
								{spaceName}
							</Box>
						</Flex>
					</Pressable>
				)}
			/>
			{shouldOpenDropdown && <Separator />}
			{Boolean(contentTitle) && <Box xcss={currentPageBreadcrumbStyles}>{contentTitle}</Box>}
			<ExperienceSuccess name={BREADCRUMBS_EXPERIENCE} />
		</Flex>
	);
};
