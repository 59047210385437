import gql from 'graphql-tag';

export const ModernizedBreadcrumbsQuery = gql`
	query ModernizedBreadcrumbsQuery(
		$spaceKey: String!
		$contentId: ID
		$skipContent: Boolean!
		$includeAlias: Boolean = false
	) {
		space(key: $spaceKey) {
			alias @include(if: $includeAlias)
			id
			name
			icon {
				path
			}
			homepage {
				id
			}
		}
		content(id: $contentId, status: ["current", "draft", "archived"]) @skip(if: $skipContent) {
			nodes {
				id
				type
				status
				title
				properties(keys: ["emoji-title-published", "emoji-title-draft"]) {
					nodes {
						id
						key
						value
					}
				}
				ancestors {
					id
					type
					title
					status
					properties(keys: ["emoji-title-published"]) {
						nodes {
							id
							key
							value
						}
					}
				}
			}
		}
	}
`;
